<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Pilih Metode Kirim Paket</h5>
            <p class="mb-0 text-sm">
              Silahkan pilih cara dan metode kirim paket yang ingin dilakukan.
            </p>
          </div>
          <div
            class="card-body"
            style="border-top: 1px solid #eeeeee;padding-top: 100px;padding-bottom: 100px;"
          >
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="box-link align-middle text-center"
                      @click.prevent="createSendPackages('manually')"
                    >
                      <div>
                        KIRIM PAKET<br />
                        <h3>SENDIRI</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body text-center">
                    <div
                      class="box-link align-middle text-center"
                      @click.prevent="createSendPackages('official')"
                    >
                      <div>
                        KIRIM PAKET MELALUI<br />
                        <h4>OFFICIAL DROPSHIP STORE</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="box-link box-link-medium align-middle text-center"
                    >
                      <div>
                        Import Data Paket (CSV)<br />
                        <h3><i class="fa fa-file-csv"></i> UPLOAD</h3>
                        <GenUpload 
                        v-if="uploadFormImport"
                        v-show="true" 
                        style="margin-top:-30px;margin-bottom:-40px;" 
                        :display_label="upload.display_label" 
                        :display_file_after_upload="upload.display_file_after_upload"
                        :accept="upload.accept"
                        :url_upload="upload.urlUpload"
                        pathfolder="/import"
                        type="csv"
                        @uploadcallback="getUploadCallback"
                        @successuploadcallback="successUploadCallback"
                        />
                        
                      </div>
                    </div>
                  </div>
                </div>
                <p class="text-center">
                  Silahkan lihat panduan upload file import.
                  <a :href="downloadExampleURL" class="text-primary">Download disini</a>
                </p>
              </div>
              <div class="col-md-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal v-model:show="modals.classic" size="lg">
    <div class="p-2">
      <h5>Official Dropship Store</h5>
      <div class="row p-4">
        <div
          v-for="officialDropship in officialDropshipStore"
          :key="officialDropship.code"
          class="col-md-6 card"
        >
          <div class="card-body">
            <b>{{ officialDropship.name }}</b>
            <p>
              {{ officialDropship.address }}<br />
              {{ officialDropship.phone }}, {{ officialDropship.email }}
            </p>

            <argon-button
              color="primary"
              size="sm"
              variant="gradient"
              class="my-4 mb-2"
              @click="selectedOfficialDropshipStore(officialDropship)"
              >Gunakan</argon-button
            >
          </div>
        </div>
      </div>

      <hr style="margin-bottom: -20px" />
      <button
        type="button"
        class="btn btn-link"
        style="margin-top: 35px"
        @click="modals.classic2 = false"
      >
        Close
      </button>
    </div>
  </modal>
</template>

<script>
import Auth from "@/components/auth/Auth";
import axios from "axios";
import Modal from "@/components/Modal";
import { ElLoading } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import GenUpload from "@/components/Generate/GenUpload.vue";

export default {
  name: "SendPackages",
  components: {
    Auth,
    Modal,
    ArgonButton,
    GenUpload
  },
  data() {
    return {
      modals: {
        classic: false,
      },
      officialDropshipStore: [],
      createmode: "",
      selected: {
        officialDropshipStore: [],
      },
      upload: {
        label: "",
        formatInfo: "",
        sizeInfo: "",
        type: "",
        accept: ".csv",
        urlUpload: "api/v1/send_packages/import",
        urlRead: "",
        pathFolder: "",
        display_file_after_upload: false,
        display_label: false,
      },
      downloadExampleURL: "",
      exampleFileName: "file-csv-import-example-inputorder.v1.0.0.csv",
      uploadFormImport: true,
    };
  },
  created() {
    //this.getOfficialDropshipStore();
    this.setDownloadExampleURL();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    setDownloadExampleURL() {
      this.downloadExampleURL = axios.defaults.baseURL+'/uploads/import/'+this.exampleFileName;
    },
    async getOfficialDropshipStore() {
      this.loadingService();
      try {
        const response = await axios.get(
          "api/v1/official_dropship_store/showlistaddress",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.officialDropshipStore = response.data.datatables;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    createSendPackages(mode) {
      if (mode == "manually") {
        this.$router.push({
          name: "CreateSendPackages",
          params: { mode: "manual" },
        });
      } else if (mode == "official") {
        this.modals.classic = true;
        if(this.officialDropshipStore == '') {
          this.getOfficialDropshipStore();
        }
      }
    },
    selectedOfficialDropshipStore(dataOfficialDropship) {
      this.selected.officialDropshipStore = dataOfficialDropship;
      this.$router.push({
        name: "CreateSendPackages",
        params: {
          mode: "official",
          selected: JSON.stringify(dataOfficialDropship),
        },
      });
    },
    getUploadCallback(values) {
      this.uploadFormImport = values;
      // if(values == true) {
      //   this.$router.push({path: '/orders/bulkpackages'})
      // }
    },
    successUploadCallback(values) {
      if(values == true) {
        this.$router.push({path: '/orders/bulkpackages'});
      }
    }
  },
};
</script>

<style scoped>
.box-link {
  height: 250px;
  border: 2px dashed #cccccc;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-link-medium {
  height: 170px;
}
.box-link:hover {
  cursor: pointer;
  border: 2px dashed #6c6be4;
}
.box-link:hover h3 {
  color: #6c6be4;
}
.box-link:hover h4 {
  color: #6c6be4;
}
</style>
